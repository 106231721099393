import React from "react"

const initialState = {
  isOrganizationView: false,
  isFormModalOpen: false,
  isThanksModallOpen: false,
  isContactSuccessModalOpen: false,
}

const AppContext = React.createContext(initialState)

class AppProvider extends React.Component {
  state = {
    isOrganizationView: false,
    isFormModalOpen: false,
    isThanksModallOpen: false,
    isContactSuccessModalOpen: false,
  }
  enableAdoptersView = () => {
    let isOrganizationView = false
    this.setState({ isOrganizationView })
  }
  enableOrganizationView = () => {
    let isOrganizationView = true
    this.setState({ isOrganizationView })
  }
  openFormDialog = () => {
    let isFormModalOpen = true
    this.setState({ isFormModalOpen })
  }
  closeFormDialog = () => {
    let isFormModalOpen = false
    this.setState({ isFormModalOpen })
  }
  openThanksDialog = () => {
    let isThanksModallOpen = true
    this.setState({ isThanksModallOpen })
  }
  closeThanksDialog = () => {
    let isThanksModallOpen = false
    this.setState({ isThanksModallOpen })
  }
  openContactSuccessDialog = () => {
    let isContactSuccessModalOpen = true
    this.setState({ isContactSuccessModalOpen })
  }
  closeContactSuccessDialog = () => {
    let isContactSuccessModalOpen = false
    this.setState({ isContactSuccessModalOpen })
  }
  render() {
    const { children } = this.props
    const { isOrganizationView } = this.state
    const { isFormModalOpen } = this.state
    const { isThanksModallOpen } = this.state
    const { isContactSuccessModalOpen } = this.state
    return (
      <AppContext.Provider
        value={{
          isOrganizationView,
          isFormModalOpen,
          isThanksModallOpen,
          isContactSuccessModalOpen,
          enableAdoptersView: this.enableAdoptersView,
          enableOrganizationView: this.enableOrganizationView,
          openFormDialog: this.openFormDialog,
          closeFormDialog: this.closeFormDialog,
          openThanksDialog: this.openThanksDialog,
          closeThanksDialog: this.closeThanksDialog,
          openContactSuccessDialog: this.openContactSuccessDialog,
          closeContactSuccessDialog: this.closeContactSuccessDialog,
        }}
      >
        {children}
      </AppContext.Provider>
    )
  }
}
export default AppContext
export { AppProvider }
