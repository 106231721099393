import "./src/styles/global.css"
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { AppProvider } from "./src/context/app_context"
import "firebase/analytics"

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LfXVlYaAAAAANFIFLSXwTmi0Rdn5iGAYLNUQ0_L">
    <AppProvider>{element}</AppProvider>
  </GoogleReCaptchaProvider>
)
